<template>
  <div>
    <div class="w-screen h-screen bg-white flex items-center px-6">
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  async mounted() {
    if (!this.$route.params?.email || !this.$route.params?.token) {
      this.actions.alert.showError({ message: 'Something went wrong! Please try again' });
      return false;
    }
    try {
      const { verifyEmail } = await this.actions.verifyEmail({
        email: this.$route.params.email,
        token: this.$route.params.token
      });
      console.log(verifyEmail, 'verifyEmail')
      if (verifyEmail?.success) {
        await this.actions.loginWithToken({ token: verifyEmail?.token, userId: verifyEmail?.userId });
        this.actions.alert.showSuccess({ message: 'Verified Email Successfully!' })
        if (this.state.currentUser?.groups?.find(g => g?.name === 'ecosystem-owner')) {
          if (!this.state.currentUser?.company?.id) {
            this.$router.push({ name: 'Register Company' });
            return false;
          }
          if (this.state.currentUser?.paymentMethods?.length === 0) {
            this.$router.push({ name: 'Setup Payment' });
            return false;
          }
        } else {
          if (this.state.currentUser?.id && this.state.currentUser?.tags?.length === 0) {
            this.$router.push({ name: 'Demographic Information' });
            return false;
          }
          if (this.state.currentUser?.id && this.state.currentUser?.tags?.filter(t => t?.category === 'DESCRIPTORS')?.length === 0 && this.state.currentUser?.id && this.state.currentUser?.tags?.filter(t => t?.category === 'DESCRIPTORS')?.length === 0) {
            this.$router.push({ name: 'Interest And Activity' });
            return false;
          }
        }
        if (this.state.isLoggedIn) {
          if (this.state.currentUser?.groups?.find(g => g?.name === 'ecosystem-owner')) {
            this.actions.setCurrentCompany(json(this.state.currentUser?.company));
            this.$router.push({ name: 'Ecosystem Detail', params: { companyId: this.state.currentUser?.company?.id } })
          } else {
            this.$router.push('/')
          }
        }
      } else {
        this.actions.logout();
        this.$router.push('/login');
      }
    } catch (e) {
      console.log(e.response)
      this.actions.logout();
      this.$router.push('/login')
    }
  },
};
</script>

<style lang="css" scoped></style>
